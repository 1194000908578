import { Component, OnDestroy, OnInit } from "@angular/core";
import { MitigationStats, StatsResult } from "@/_models";
import { AircraftService, AssessmentService, UserService } from "@/_services";
import { from, Observable, Subscription } from "rxjs";
import {
    debounceTime,
    filter,
    map,
    shareReplay,
    switchMap,
    take,
    tap,
} from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PdfConfirmExportComponent } from "@/components/shared/modals/pdf-confirm-export/pdf-confirm-export.component";
import { LoadingModalComponent } from "@/components/shared/loading-modal/loading-modal.component";
import { ReportExportResultDto } from "@/_models/stats-export-response";
import { expandingFabAnimations } from "@/_utility/animations";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { RadioOption } from "@/components/shared/radio-list/radio-list.component";
import { FilterService } from "@/_services/filter.service";

@Component({
    templateUrl: "./statistics.component.html",
    styleUrls: ["./statistics.component.scss"],
    animations: [expandingFabAnimations],
})
export class StatisticsComponent implements OnInit, OnDestroy {
    stats$: Observable<StatsResult>;
    statsData$: Observable<MitigationStats>;

    filterForm: UntypedFormGroup;
    filterFormSubscription: Subscription;
    currentFilters;

    timeFrameList: RadioOption[] = [
        { label: "All", value: "" },
        { label: "Next 30 days", value: 30 },
        { label: "Next 7 days", value: 7 },
        { label: "Tomorrow", value: 1 },
        { label: "Today", value: 0 },
        { label: "Yesterday", value: -1 },
        { label: "Past 7 Days", value: -7 },
        { label: "Past 30 Days", value: -30 },
        { label: "Past Year", value: -365 },
        { label: "Custom Time", value: "custom" },
    ];
    userList$: Observable<RadioOption[]>;
    aircraftList$: Observable<RadioOption[]>;
    formList$: Observable<RadioOption[]>;

    constructor(
        private userService: UserService,
        private aircraftService: AircraftService,
        private modalService: NgbModal,
        private toastService: ToastrService,
        private formBuilder: UntypedFormBuilder,
        private assessmentService: AssessmentService,
        private filterService: FilterService,
    ) {}

    ngOnInit(): void {
        this.buildFilterForm();

        this.userList$ = this.userService.getUsers().pipe(
            map((users) =>
                users.map((user) => {
                    return { label: user.fullName, value: user._id };
                }),
            ),
            shareReplay(),
        );

        this.aircraftList$ = this.aircraftService.listAircraft().pipe(
            map((aircrafts) =>
                aircrafts.map((aircraft) => {
                    return { label: aircraft.designation, value: aircraft._id };
                }),
            ),
            shareReplay(),
        );

        this.formList$ = this.assessmentService
            .getAssessmentsByType("assessment")
            .pipe(
                map((assessments) => [
                    { label: "All", value: "" },
                    ...assessments.map((assessment) => {
                        return {
                            label: assessment.title,
                            value: assessment._id,
                        };
                    }),
                ]),
                shareReplay(),
            );
    }

    ngOnDestroy(): void {
        if (this.filterFormSubscription) {
            this.filterFormSubscription.unsubscribe();
        }
    }

    buildFilterForm() {
        this.filterForm = this.formBuilder.group({
            responsiblePilot: [""],
            timeFrame: [""],
            flightDate: [[]],
            aircraft: [""],
            airport: [""],
            assessment: [""],
        });

        this.filterFormSubscription = this.filterForm.valueChanges
            .pipe(
                debounceTime(500),
                tap((changes) =>
                    localStorage.setItem(
                        "insight-list-filters",
                        JSON.stringify(changes),
                    ),
                ),
                tap((changes) => (this.currentFilters = { ...changes })),
                tap(() => this.updateFlightList()),
            )
            .subscribe();

        if (!!localStorage.getItem("insight-list-filters")) {
            const filters = JSON.parse(
                localStorage.getItem("insight-list-filters"),
            );
            this.filterForm.patchValue(filters, {
                emitEvent: false,
                onlySelf: true,
            });
            this.currentFilters = { ...filters };
            this.updateFlightList();
        } else {
            this.updateFlightList();
        }
    }

    buildFilterQuery() {
        return this.filterService.buildFilterQuery(this.filterForm);
    }

    updateFlightList() {
        this.stats$ = this.userService
            .getUserStats(this.buildFilterQuery())
            .pipe(shareReplay());
        this.statsData$ = this.stats$.pipe(
            map((stats) => stats.data),
            shareReplay(),
        );
    }

    exportPDF() {
        const confirmationModal = this.modalService.open(
            PdfConfirmExportComponent,
        );
        const component =
            confirmationModal.componentInstance as PdfConfirmExportComponent;
        component.currentFilters = this.currentFilters;
        component.userList$ = this.userList$;
        component.aircraftList$ = this.aircraftList$;
        component.formList$ = this.formList$;

        from(confirmationModal.result)
            .pipe(
                take(1),
                filter((result) => !!result),
                map((result) => result as "pdf" | "csv"),
                switchMap((result: "pdf" | "csv") => {
                    const loading = this.modalService.open(
                        LoadingModalComponent,
                    );
                    const loadingComponent =
                        loading.componentInstance as LoadingModalComponent;
                    loadingComponent.message = "Generating PDF";

                    return this.userService
                        .exportStats(this.buildFilterQuery(), result)
                        .pipe(
                            map((response: ReportExportResultDto) => {
                                const link = document.createElement("a");
                                link.href = window.URL.createObjectURL(
                                    response.data,
                                );
                                link.download = response.filename;
                                link.setAttribute("target", "_blank");
                                return link;
                            }),
                            tap(
                                (next) => {
                                    loading.close();
                                },
                                (error) => {
                                    loading.close();
                                },
                            ),
                        );
                }),
            )
            .subscribe({
                next: (link) => {
                    link.click();
                    confirmationModal.close();
                    link.remove();
                },
                error: (err) => {
                    confirmationModal.close();
                    this.toastService.error(err, "Unable to generate PDF");
                },
            });
    }
}
