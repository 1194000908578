import { NgModule } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { PagesComponent } from "@/components/pages/pages.component";
import { SharedComponentsModule } from "@/components/shared/shared-components.module";
import { RouterModule, Routes } from "@angular/router";
import { BakeryComponent } from "@/components/error-test/bakery.component";
import { ReactiveFormsModule } from "@angular/forms";
import { SubscriptionGuard } from "@/_guards/subscription-guard.service";
import { StatisticsComponent } from "@/components/statistics/statistics.component";
import { DesktopMenuComponent } from "@/components/pages/desktop-menu/desktop-menu.component";
import { MobileMenuComponent } from "@/components/pages/mobile-menu/mobile-menu.component";
import { MobileFullMenuComponent } from "@/components/pages/mobile-menu/mobile-full-menu/mobile-full-menu.component";
import { AvailableForMobileGuard } from "@/_guards/available-for-mobile.guard";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSidenavModule } from "@angular/material/sidenav";
import { InsightsFilterTopbarComponent } from "@/components/statistics/insights-filter-topbar/insights-filter-topbar.component";
import { InsightsFilterSidebarComponent } from "@/components/statistics/insights-filter-sidebar/insights-filter-sidebar.component";
import { WelcomeTrialComponent } from "@/components/welcome-trial/welcome-trial.component";
import { QuickStartCardComponent } from "@/components/welcome-trial/quick-start-card/quick-start-card.component";
import { SetupLineComponent } from "@/components/welcome-trial/setup-line/setup-line.component";
import { EmpowerLineComponent } from "@/components/welcome-trial/empower-line/empower-line.component";
import { activeTrialOnlyGuard } from "@/_guards/active-trial-only.guard";

const ROUTES: Routes = [
    {
        path: "",
        component: PagesComponent,
        children: [
            {
                path: "welcome-trial",
                component: WelcomeTrialComponent,
                canActivate: [activeTrialOnlyGuard],
            },
            {
                path: "performance",
                component: StatisticsComponent,
                canActivate: [SubscriptionGuard],
            },
            {
                path: "settings",
                component: MobileFullMenuComponent,
                canActivate: [AvailableForMobileGuard],
            },
            {
                path: "tasks",
                loadChildren: () =>
                    import("../flight/flight.module").then(
                        (m) => m.FlightModule,
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                // #1061: retain compatibility for old links out in the wild
                path: "flights/:id/summary",
                redirectTo: (redirectData) => {
                    const id = redirectData.params.id;
                    return `/tasks/${id}/summary`;
                },
            },
            {
                // https://github.com/PreflightMitigator/pfm-webapp/issues/1171
                path: "frats/:id/calculate",
                redirectTo: (redirectData) => {
                    return `/reports/${redirectData.params.id}/calculate`;
                },
            },
            {
                path: "assurance",
                loadChildren: () =>
                    import("../safety-assurance/safety-assurance.module").then(
                        (m) => m.SafetyAssuranceModule,
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                path: "policies",
                loadChildren: () =>
                    import("../safety-policy/safety-policy.module").then(
                        (m) => m.SafetyPolicyModule,
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                path: "promotions",
                loadChildren: () =>
                    import(
                        "@/components/safety-promotion/safety-promotion.module"
                    ).then((m) => m.SafetyPromotionModule),
                canActivate: [SubscriptionGuard],
            },
            {
                path: "manuals",
                loadChildren: () =>
                    import("../manuals/manuals.module").then(
                        (m) => m.ManualsModule,
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                path: "assessments",
                loadChildren: () =>
                    import("../assessment/assessment.module").then(
                        (m) => m.AssessmentModule,
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                path: "profile",
                loadChildren: () =>
                    import("../profile/profile.module").then(
                        (m) => m.ProfileModule,
                    ),
            },
            {
                path: "bakery",
                component: BakeryComponent,
            },
            /*{
                path: "**",
                pathMatch: "full",
                redirectTo: "welcome-trial",
            },*/
        ],
    },
];

@NgModule({
    declarations: [
        PagesComponent,
        DesktopMenuComponent,
        MobileMenuComponent,
        WelcomeTrialComponent,
        QuickStartCardComponent,
        SetupLineComponent,
        EmpowerLineComponent,
        StatisticsComponent,
        InsightsFilterSidebarComponent,
        InsightsFilterTopbarComponent,
        BakeryComponent,
        MobileFullMenuComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedComponentsModule,
        RouterModule.forChild(ROUTES),
        ReactiveFormsModule,
        MatExpansionModule,
        MatSidenavModule,
        NgOptimizedImage,
    ],
})
export class PagesModule {}
