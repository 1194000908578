import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MenuService } from "app/_services/menu.service";
import { Observable } from "rxjs";
import { MenuSection } from "app/_models/menu-item";
import { AuthenticationService } from "app/_services";

@Component({
    selector: "app-mobile-full-menu",
    templateUrl: "./mobile-full-menu.component.html",
    styleUrls: ["./mobile-full-menu.component.scss"],
})
export class MobileFullMenuComponent implements OnInit {
    mainMenu$: Observable<MenuSection>;
    @Output() menuItemClickled: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private menuService: MenuService,
        private authService: AuthenticationService,
    ) {}

    ngOnInit() {
        this.mainMenu$ = this.menuService.createMobileSettingsMenu();
    }

    logout() {
        this.authService.logout().subscribe();
    }
}
