<app-card [noMargin]="true" [noPadding]="true" [showHeader]="false">
    <div card-body class="quick-start-card flex flex-column gap-10px">
        <img [src]="iconSrc" height="80" [id]="iconId" alt="">
        <div class="flex flex-column">
            <h3>{{title}}</h3>
            <span>{{description}}</span>
        </div>
        <ng-content></ng-content>
    </div>
</app-card>
