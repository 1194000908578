<div class="container" [formGroup]="toastForm">
    <label for="toast-type">ToastType</label>

    <select id="toast-type" formControlName="type">
        <option [value]="'success'">Success</option>
        <option [value]="'error'">Error</option>
    </select>

    <label>Show close button</label>
    <app-radio-list
        [currentSelection]="true"
        [options]="closeButtonOptions"
        (onChange)="selectCloseButton($event)"></app-radio-list>

    <label for="title">Title</label>
    <input type="text" id="title" formControlName="title" />

    <label for="message">Message</label>
    <input type="text" id="message" formControlName="message" />

    <button class="btn btn-primary" (click)="makeToast()">Make toast</button>

    <button class="btn btn-secondary" (click)="closeToast()">
        Close toast
    </button>
</div>
