import { Component, Input } from "@angular/core";

@Component({
    selector: "app-setup-line",
    templateUrl: "./setup-line.component.html",
    styleUrl: "./setup-line.component.scss",
})
export class SetupLineComponent {
    @Input() action: {
        icon: string;
        title: string;
        description: string;
        link: string;
    };
}
