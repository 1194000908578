import { Component, OnInit } from "@angular/core";
import { settingsMenuAnimations } from "@/_utility/animations";

@Component({
    selector: "app-mobile-menu",
    templateUrl: "./mobile-menu.component.html",
    styleUrls: ["./mobile-menu.component.scss"],
    animations: settingsMenuAnimations,
})
export class MobileMenuComponent implements OnInit {
    isMenuOpened: boolean;

    constructor() {}

    ngOnInit() {}

    toggleMenu(state: boolean) {
        this.isMenuOpened = state;
    }
}
