<div class="welcome-container">
    <div class="container flex flex-column" [style.grid-column]="'span 2'">
        <h4 class="date-line">{{currentDate | date:'MMMM d, y':'UTC'}}</h4>
        <span class="welcome-line-1">
            Hello {{ (currentUser$ | async)?.firstName }}
            <img ngSrc="/assets/icons/welcome.svg" height="30" width="30" alt="Welcome">
        </span>
        <span class="welcome-line-2">Welcome to Preflight Mitigator.</span>
    </div>

    <div class="container container-3 flex flex-column gap-20px" [style.grid-column]="'span 3'">
        <div class="step-header">
            <h2>Quick Start</h2>
            <p>Get familiar with how everything works by testing out a few key actions.</p>
        </div>

        <div class="quick-start-grid">
            <app-quick-start-card
                [iconSrc]="'/assets/img/welcome-trial/risk-assessment.svg'"
                [iconId]="'img-risk-assessment'"
                [title]="'Risk Assessment'"
                [description]="'Test out the default IS-BAO risk assessment form.'">
                <button class="btn-secondary" [style.padding]="'5px 15px'" (click)="takeAssessment()">
                    Take Assessment
                </button>
            </app-quick-start-card>

            <app-quick-start-card
                [iconSrc]="'/assets/img/welcome-trial/hazard-report.svg'"
                [iconId]="'img-hazard-report'"
                [title]="'Hazard Report'"
                [description]="'Try submitting a test hazard or incident report for review.'">
                <button class="btn-secondary" [style.padding]="'5px 15px'" (click)="submitHazard()">
                    Submit Hazard
                </button>
            </app-quick-start-card>

            <app-quick-start-card
                [iconSrc]="'/assets/img/welcome-trial/risk-mitigation.svg'"
                [iconId]="'img-risk-mitigation'"
                [title]="'Risk Mitigation'"
                [description]="'Fill out a root cause analysis and create a corrective action plan.'">
                <button class="btn-secondary" [style.padding]="'5px 15px'" (click)="mitigateRisk()">
                    Mitigate Risk
                </button>
            </app-quick-start-card>

            <app-quick-start-card
                [iconSrc]="'/assets/img/welcome-trial/safety-promotion.svg'"
                [iconId]="'img-safety-promotion'"
                [title]="'Safety Promotion'"
                [description]="'Build a safety announcement and send it out to the crew.'">
                <button class="btn-secondary" [style.padding]="'5px 15px'" (click)="sendPromotion()">
                    Send Promotion
                </button>
            </app-quick-start-card>
        </div>
    </div>

    <div *ngIf="isAtLeastAdmin$ | async" class="container container-2 flex flex-column gap-20px">
        <div class="step-header">
            <h2>Setup Essentials</h2>
            <p>Unlock the power of Preflight Mitigator by completing your account setup.</p>
        </div>
        <app-card [noMargin]="true" [noPadding]="true" [showHeader]="false">
            <div card-body class="setup-container">
                <app-setup-line *ngFor="let action of setupActionList" [action]="action">
                </app-setup-line>
            </div>
        </app-card>
    </div>

    <div *ngIf="isAtLeastAdmin$ | async" class="container flex flex-column gap-20px">
        <div class="step-header">
            <h2>Empower Your SMS</h2>
            <p>Establish an effective SMS with these tips.</p>
        </div>

        <div class="flex flex-column gap-20px">
            <app-empower-line
                [iconSrc]="'/assets/img/welcome-trial/empower-manual.svg'"
                [title]="'Leverage a Custom SMS Manual'"
                [description]="'Our FAA Part 5 / ICAO SMS manual by Aviation Safety Solutions will streamline your FAA Part 5 approval.'">
                <button class="btn-secondary-simple" [style.padding]="'0'" (click)="requestManual()">
                    Request Manual
                    <app-custom-icon
                        [iconPath]="'assets/icons/general-icons.svg'"
                        [imageName]="'icon-chevron-right'">
                    </app-custom-icon>
                </button>
            </app-empower-line>

            <app-empower-line
                [iconSrc]="'/assets/img/welcome-trial/empower-scheduling.svg'"
                [title]="'Integrate Your Flight Scheduling'"
                [description]="'Ensure a risk assessment is completed for every trip by automating the setup process for your pilots.'">
                <button class="btn-secondary-simple" [style.padding]="'0'" (click)="integrateScheduling()">
                    Integrate Scheduling
                    <app-custom-icon
                        [iconPath]="'assets/icons/general-icons.svg'"
                        [imageName]="'icon-chevron-right'">
                    </app-custom-icon>
                </button>
            </app-empower-line>

            <app-empower-line
                [iconSrc]="'/assets/img/welcome-trial/empower-risk-form.svg'"
                [title]="'Customize Your Risk Form'"
                [description]="'Adjust the risk assessment to track and evaluate the specific risk factors relevant to your operations.'">
                <button class="btn-secondary-simple" [style.padding]="'0'" (click)="modifyRiskForm()">
                    Modify Risk Form
                    <app-custom-icon
                        [iconPath]="'assets/icons/general-icons.svg'"
                        [imageName]="'icon-chevron-right'">
                    </app-custom-icon>
                </button>
            </app-empower-line>
        </div>
    </div>
</div>
