import { Component, Input } from "@angular/core";

@Component({
    selector: "app-quick-start-card",
    templateUrl: "./quick-start-card.component.html",
    styleUrl: "./quick-start-card.component.scss",
})
export class QuickStartCardComponent {
    @Input() iconSrc: string;
    @Input() iconId: string;
    @Input() title: string;
    @Input() description: string;
}
