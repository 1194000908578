import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { UserDm } from "@/_models";
import {
    AuthenticationService,
    USER_IS_AT_LEAST_ADMIN,
    UserService,
} from "@/_services";
import { MenuSection } from "@/_models/menu-item";
import { shareReplay } from "rxjs/operators";
import { Observable } from "rxjs";
import { MenuService } from "@/_services/menu.service";
import UserModel = UserDm.UserModel;

@Component({
    selector: "app-desktop-menu",
    templateUrl: "./desktop-menu.component.html",
    styleUrls: ["./desktop-menu.component.scss"],
})
export class DesktopMenuComponent implements OnInit {
    @Output() enterEdit: EventEmitter<void> = new EventEmitter<void>();
    @Input() showAccountLink = false;

    hasLinkedLogins = false;

    currentUser$: Observable<UserModel>;
    mainMenu$: Observable<MenuSection>;
    formMenu$: Observable<MenuSection>;

    showForms = true;

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private menuService: MenuService,
        @Inject(USER_IS_AT_LEAST_ADMIN)
        public isAtLeastAdmin$: Observable<boolean>,
    ) {
        this.hasLinkedLogins = this.authenticationService.hasLinkedLogins();
    }

    ngOnInit() {
        this.currentUser$ = this.userService
            .getCurrentUserAsync()
            .pipe(shareReplay());
        this.mainMenu$ = this.menuService.createDesktopMenu();
        this.formMenu$ = this.menuService.createFormMenu();
    }

    toggleCollapse() {
        this.showForms = !this.showForms;
    }
}
