import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { RadioOption } from "@/components/shared/radio-list/radio-list.component";

@Component({
    selector: "app-insights-filter-topbar",
    templateUrl: "./insights-filter-topbar.component.html",
    styleUrls: ["./insights-filter-topbar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsFilterTopbarComponent implements OnInit {
    @Input() filterForm: UntypedFormGroup;
    @Input() currentFilters;
    @Output() filterButtonClicked: EventEmitter<void> =
        new EventEmitter<void>();

    @Input() timeFrameList: RadioOption[];
    @Input() userList: RadioOption[];
    @Input() aircraftList: RadioOption[];
    @Input() formList: RadioOption[];

    constructor() {}

    ngOnInit() {}

    openFilterMenu() {
        this.filterButtonClicked.emit();
    }

    isDisplayable(filter) {
        if (filter.key === "airport" && filter.value._id) {
            return true;
        }

        if (filter.key === "airport" && !filter.value._id) {
            return false;
        }

        return filter.value.length;
    }

    resetFilter(filterKey: string) {
        if (filterKey === "flightDate") {
            return this.filterForm.get(filterKey).setValue([]);
        }
        return this.filterForm.get(filterKey).setValue("");
    }
}
