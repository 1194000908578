<div class="app-content filter">
    <div class="filter-header">
        <h2>Filter</h2>
        <span (click)="close()">
            <app-custom-icon
                [iconPath]="'assets/icons/general-icons.svg'"
                [imageName]="'icon-close'">
            </app-custom-icon>
        </span>
    </div>
    <mat-accordion>
        <app-sidebar-filter-panel
            *ngIf="filterForm | formControl:'responsiblePilot' as control"
            [title]="'Individual'"
            [controls]="[control]"
            [isEmpty]="!control.value">
            <app-select
                [control]="control"
                [optionList]="userList"
                [placeholder]="'Select Pilot'"></app-select>
        </app-sidebar-filter-panel>

        <app-sidebar-filter-panel
            *ngIf="filterForm | formControl:'timeFrame' as control"
            [title]="'Time Frame (UTC)'"
            [controls]="[control, filterForm | formControl:'flightDate']"
            [isEmpty]="!control.value">
            <app-radio-list
                [options]="timeFrameList"
                [view]="'button-view'"
                [direction]="'list-column'"
                [currentSelection]="control.value"
                (onChange)="setDateRangeValue(control, $event)"></app-radio-list>
        </app-sidebar-filter-panel>

        <app-sidebar-filter-panel
            *ngIf="filterForm | formControl:'aircraft' as control"
            [title]="'Aircraft'"
            [controls]="[control]"
            [isEmpty]="!control.value">
            <app-select
                [control]="control"
                [optionList]="aircraftList"
                [placeholder]="'Select Aircraft'"></app-select>
        </app-sidebar-filter-panel>

        <app-sidebar-filter-panel
            *ngIf="filterForm | formControl:'airport' as control"
            [title]="'Airport'"
            [controls]="[control]"
            [isEmpty]="!control.value?.icao_code">
            <airport-picker [control]="control" [height]="40"></airport-picker>
        </app-sidebar-filter-panel>

        <app-sidebar-filter-panel
            *ngIf="filterForm | formControl:'assessment' as control"
            [title]="'Form'"
            [controls]="[control]"
            [isEmpty]="!control.value">
            <app-radio-list
                [options]="formList"
                [view]="'button-view'"
                [direction]="'list-column'"
                [currentSelection]="control.value"
                (onChange)="control.setValue($event)"></app-radio-list>
        </app-sidebar-filter-panel>
    </mat-accordion>
</div>
