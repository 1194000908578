import { Component, Input } from "@angular/core";

@Component({
    selector: "app-empower-line",
    templateUrl: "./empower-line.component.html",
    styleUrl: "./empower-line.component.scss",
})
export class EmpowerLineComponent {
    @Input() iconSrc: string;
    @Input() title: string;
    @Input() description: string;
}
