<div class="setup-line" [routerLink]="action.link">
    <img [src]="action.icon" [alt]="action.title">
    <div class="flex flex-column">
        <h3>{{action.title}}</h3>
        <span>{{action.description}}</span>
    </div>
    <a class="link">
        <app-custom-icon
            [iconPath]="'assets/icons/general-icons.svg'"
            [imageName]="'icon-chevron-right'">
        </app-custom-icon>
    </a>
</div>
