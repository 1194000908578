import { Component, OnInit } from "@angular/core";
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    NavigationEnd,
    Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { filter, map, shareReplay, startWith } from "rxjs/operators";
import { LastScrollDirection } from "@/_directives/scroll-state.directive";

@Component({
    selector: "app-pages",
    templateUrl: "./pages.component.html",
    styleUrls: ["./pages.component.scss"],
})
export class PagesComponent implements OnInit {
    hideMenu$: Observable<{
        hideDesktopMenu: boolean;
        hideMobileMenu: boolean;
    }>;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.hideMenu$ = this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
            startWith(this.router),
            map(() =>
                this.childrenCheck(this.activatedRoute.snapshot.firstChild),
            ),
            shareReplay(),
        );
    }

    childrenCheck(snapshot: ActivatedRouteSnapshot): {
        hideDesktopMenu: boolean;
        hideMobileMenu: boolean;
    } {
        if (
            snapshot.data.hasOwnProperty("hideDesktopMenu") ||
            snapshot.data.hasOwnProperty("hideMobileMenu")
        ) {
            return {
                hideDesktopMenu: snapshot.data["hideDesktopMenu"] || false,
                hideMobileMenu: snapshot.data["hideMobileMenu"] || false,
            };
        }

        if (snapshot.children.length) {
            return this.childrenCheck(snapshot.firstChild);
        }

        return { hideDesktopMenu: false, hideMobileMenu: false };
    }
}
