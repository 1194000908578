<app-page-topbar [title]="'Performance'">
    <div *appDesktop class="button" (click)="exportPDF()">
        <span>Export Insights</span>
        <!-- <img src="/assets/icon/icon-export-pdf-arrow.svg" alt="Export" /> -->
    </div>
</app-page-topbar>

<mat-drawer-container [hasBackdrop]="true">
    <mat-drawer #sidenav [mode]="'over'">
        <app-insights-filter-sidebar
            [filterForm]="filterForm"
            (closeButtonClicked)="sidenav.close()"
            [userList]="userList$ | async"
            [timeFrameList]="timeFrameList"
            [aircraftList]="aircraftList$ | async"
            [formList]="formList$ | async"></app-insights-filter-sidebar>
    </mat-drawer>
    <mat-drawer-content class="app-content listings">
        <app-insights-filter-topbar
            [filterForm]="filterForm"
            [currentFilters]="currentFilters"
            (filterButtonClicked)="sidenav.open()"
            [userList]="userList$ | async"
            [timeFrameList]="timeFrameList"
            [aircraftList]="aircraftList$ | async"
            [formList]="formList$ | async"></app-insights-filter-topbar>

        <app-loading-indicator
            *ngIf="!(stats$ | async); else content"></app-loading-indicator>

        <ng-template #content>
            <div *ngIf="!(stats$ | async)?.meta?.results; else stats">
                <div class="empty-state">
                    <img
                        class="no-assessment"
                        id="img-empty-insights"
                        src="/assets/img/empty-insights.svg"
                        alt="No Stats to Display" />
                    <h2>No Statistics to Display</h2>
                    <p>
                        Change the filters to view different
                        <br />
                        trends and insights.
                    </p>
                    <a
                        class="open-filters"
                        (click)="sidenav.open(); $event.stopPropagation()">
                        Change Filtered View
                    </a>
                </div>
            </div>

            <ng-template #stats>
                <pilot-stats
                    *ngIf="statsData$ | async"
                    [stats$]="statsData$"></pilot-stats>
            </ng-template>

            <div
                class="footer-links"
                *ngIf="(stats$ | async)?.meta?.results > 0">
                <p>Looking for different statistics? Let us know!</p>
                <a
                    class="request"
                    target="_blank"
                    href="https://preflightmitigator.com/feature-request.html">
                    Request Specific Statistics
                </a>
            </div>
        </ng-template>

        <div class="right-corner-control blue" *appMobile>
            <a (click)="exportPDF()">
                <span>Export Insights</span>
            </a>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
