import { Component, OnInit } from "@angular/core";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { RadioOption } from "@/components/shared/radio-list/radio-list.component";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

@Component({
    selector: "app-bakery",
    templateUrl: "./bakery.component.html",
    styleUrls: ["./bakery.component.scss"],
})
export class BakeryComponent implements OnInit {
    closeButtonOptions: RadioOption[] = [
        {
            label: "Yes",
            value: true,
        },
        {
            label: "No",
            value: false,
        },
    ];
    toastForm: UntypedFormGroup;

    constructor(
        private toastService: ToastrService,
        private formBuilder: UntypedFormBuilder,
    ) {}

    ngOnInit() {
        this.toastForm = this.formBuilder.group({
            type: this.formBuilder.control("success"),
            closeButton: this.formBuilder.control(true),
            title: this.formBuilder.control(""),
            message: this.formBuilder.control(""),
        });
    }

    selectCloseButton($event: any) {
        this.toastForm.get("closeButton").patchValue($event);
    }

    makeToast() {
        const params = this.toastForm.value;
        const options: Partial<IndividualConfig> = {
            disableTimeOut: true,
        };
        options.closeButton = params.closeButton;

        this.toastService[params.type](params.message, params.title, options);
    }

    closeToast() {
        this.toastService.clear();
    }
}
