<div class="table-filters">
    <div class="filter-button" (click)="openFilterMenu()">
        <app-custom-icon
            [iconPath]="'assets/icons/general-icons.svg'"
            [imageName]="'filter'"
            [imageSize]="'18'">
        </app-custom-icon>
        <span>Filter</span>
    </div>
    <ng-container *ngFor="let filter of currentFilters | keyvalue">
        <ng-container *ngIf="isDisplayable(filter)" [ngSwitch]="filter.key">
            <app-topbar-filter-button
                *ngSwitchCase="'responsiblePilot'"
                [filter]="filter"
                [dataToDisplay]="'Individual: ' + (filter.value | findInList:userList)"
                (resetClicked)="resetFilter(filter.key)"></app-topbar-filter-button>
            <app-topbar-filter-button
                *ngSwitchCase="'flightDate'"
                [filter]="filter"
                [dataToDisplay]="filter.value | dateIntervalToString"
                (resetClicked)="resetFilter(filter.key); resetFilter('timeFrame')"></app-topbar-filter-button>
            <app-topbar-filter-button
                *ngSwitchCase="'aircraft'"
                [filter]="filter"
                [dataToDisplay]="'Aircraft: ' + (filter.value | findInList:aircraftList)"
                (resetClicked)="resetFilter(filter.key)"></app-topbar-filter-button>
            <app-topbar-filter-button
                *ngSwitchCase="'airport'"
                [filter]="filter"
                [dataToDisplay]="'Airport: ' + filter.value.icao_code + ' ' + filter.value.name"
                (resetClicked)="resetFilter(filter.key)"></app-topbar-filter-button>
            <app-topbar-filter-button
                *ngSwitchCase="'assessment'"
                [filter]="filter"
                [dataToDisplay]="'Form: ' + (filter.value | findInList:formList)"
                (resetClicked)="resetFilter(filter.key)"></app-topbar-filter-button>
            <ng-container *ngSwitchDefault></ng-container>
        </ng-container>
    </ng-container>
</div>
