import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { RadioOption } from "@/components/shared/radio-list/radio-list.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DateRangeFilterModalComponent } from "@/components/shared/modals/date-range-filter-modal/date-range-filter-modal.component";
import { from, tap } from "rxjs";

@Component({
    selector: "app-insights-filter-sidebar",
    templateUrl: "./insights-filter-sidebar.component.html",
    styleUrls: ["./insights-filter-sidebar.component.scss"],
})
export class InsightsFilterSidebarComponent implements OnInit {
    @Input() filterForm: UntypedFormGroup;
    @Output() closeButtonClicked: EventEmitter<void> = new EventEmitter<void>();

    @Input() userList: RadioOption[];
    @Input() timeFrameList: RadioOption[];
    @Input() aircraftList: RadioOption[];
    @Input() formList: RadioOption[];

    constructor(private modalService: NgbModal) {}

    ngOnInit() {}

    setDateRangeValue(control: UntypedFormControl, value: any) {
        control.setValue(value);
        if (value === "") {
            return this.filterForm.get("flightDate").setValue("");
        }
        if (value === "custom") {
            const filterModal = this.modalService.open(
                DateRangeFilterModalComponent,
            );
            const component =
                filterModal.componentInstance as DateRangeFilterModalComponent;
            component.control = this.filterForm.get(
                "flightDate",
            ) as UntypedFormControl;

            from(filterModal.result)
                .pipe(
                    tap(() => {
                        if (!this.filterForm.get("flightDate").value.length) {
                            this.setDateRangeValue(control, "");
                            control.setValue("");
                        }
                    }),
                )
                .subscribe();
        } else {
            const currentDate = new Date();
            const targetDate = new Date();
            targetDate.setDate(currentDate.getDate() + value);
            const currentDateString = currentDate
                .toISOString()
                .substring(0, 10);
            const targetDateString = targetDate.toISOString().substring(0, 10);
            return this.filterForm
                .get("flightDate")
                .setValue(
                    value >= 0
                        ? [currentDateString, targetDateString]
                        : [targetDateString, currentDateString],
                );
        }
    }

    close() {
        this.closeButtonClicked.emit();
    }
}
