<div class="left-corner-control black">
    <a class="menu"
        *ngIf="!isMenuOpened; else menu"
        (click)="$event.stopPropagation(); toggleMenu(true)">
        <app-custom-icon
            [iconPath]="'assets/icons/general-icons.svg'"
            [imageName]="'icon-menu'">
        </app-custom-icon>
    </a>
    <ng-template #menu>
        <a class="close" (click)="toggleMenu(false)">
            <div class="menu-icon">
                <app-custom-icon
                    [iconPath]="'assets/icons/general-icons.svg'"
                    [imageName]="'icon-close'">
                </app-custom-icon>
            </div>
        </a>
    </ng-template>
</div>

<app-mobile-full-menu *ngIf="isMenuOpened" (menuItemClickled)="toggleMenu(false)">
</app-mobile-full-menu>
