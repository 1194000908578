<div class="app-container settings">
    <ng-container *ngIf="!(hideMenu$ | async)?.hideDesktopMenu">
        <app-desktop-menu *appDesktop></app-desktop-menu>
    </ng-container>

    <div class="settings app-main">
        <router-outlet></router-outlet>
    </div>

    <ng-container *ngIf="!(hideMenu$ | async)?.hideMobileMenu">
        <app-mobile-menu *appMobile></app-mobile-menu>
    </ng-container>
</div>
